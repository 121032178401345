<template>
  <div id="app">
    <div class="content">
      <img
        class="logo"
        alt="Stocks House Logo"
        src="@/assets/stocks-house-logo.svg"
      />
      <h1>
        Search the best <span>stock photo websites</span> <br />with a single
        click.
        <img src="@/assets/underline.svg" alt="" />
      </h1>
      <div class="frame"></div>
      <div class="mobile-frame">
        <img src="@/assets/frame-mobile.jpg" alt="" />
      </div>
      <!-- /.frame -->
      <div class="mobile-cta">
        <a
          href="mailto:your-email@here.com?subject=Note%20to%20self%3A%20Don't%20forget%20to%20download%20this%20extension%20%F0%9F%92%90&body=Hey%20future%20me%2C%0D%0A%0D%0AWould%20you%20remember%20to%20download%20this%20cool%20extension%20when%20you%20are%20on%20Desktop%3F%0D%0A%0D%0ALove%20you%20always%2C%0D%0A%0D%0Ahttps%3A%2F%2Fstockshouse.co"
          target="_blank"
          class="button"
        >
          <span>SEND YOURSELF A REMINDER</span>
        </a>
        <img class="path" src="@/assets/path.svg" alt="" />
      </div>
      <!-- /.mobile-cta -->
      <div class="download" v-if="!isMobile">
        <a
          :href="links[browser.name]"
          class="download-button"
          v-if="!showOtherBrowsers"
        >
          <img :src="downloadSrc" alt="" />
          <span>GET {{ browser.name.toUpperCase() }} EXTENSION</span>
        </a>
        <div class="download-buttons" v-if="showOtherBrowsers">
          <a :href="links.chrome" target="_blank">
            <img src="@/assets/browser-logos/chrome.svg" alt="" />
          </a>
          <a :href="links.safari" target="_blank">
            <img src="@/assets/browser-logos/safari.svg" alt="" />
          </a>
          <a :href="links.brave" target="_blank">
            <img src="@/assets/browser-logos/brave.svg" alt="" />
          </a>
          <a :href="links.firefox" target="_blank">
            <img src="@/assets/browser-logos/firefox.svg" alt="" />
          </a>
          <a :href="links.opera" target="_blank">
            <img src="@/assets/browser-logos/opera.svg" alt="" />
          </a>
          <a :href="links.edge" target="_blank">
            <img src="@/assets/browser-logos/edge.svg" alt="" />
          </a>
        </div>
        <!-- /.download-buttons -->
        <img class="path" src="@/assets/path.svg" alt="" />
        <a
          href="javascript:;"
          v-if="!showOtherBrowsers"
          @click="showOtherBrowsers = true"
          class="other-browsers"
          >OTHER BROWSERS</a
        >
        <a
          href="javascript:;"
          @click="showOtherBrowsers = false"
          v-if="showOtherBrowsers"
          class="choose-your-browser"
          >CHOOSE YOUR BROWSER</a
        >
        <!-- /.other-browsers -->
      </div>
      <!-- /.download -->
      <MoreTools />
      <Newsletter />
      <footer>🐼 Panda Network 2021 - Always with 💕</footer>
    </div>
    <!-- /.content -->
  </div>
</template>

<script>
import MoreTools from "@/components/MoreTools.vue";
import Newsletter from "@/components/Newsletter.vue";

const { detect } = require("detect-browser");
const browser = detect();

export default {
  name: "App",
  computed: {
    downloadSrc() {
      return require("./assets/browser-logos/" + browser.name + ".svg");
    },
  },
  data: function () {
    return {
      browser,
      showOtherBrowsers: false,
      isMobile: window.innerWidth <= 698,
      links: {
        chrome:
          "https://chrome.google.com/webstore/detail/stocks-house/mpdkicmbmbljbgncgphonhgjebnapfop?hl=en",
        brave:
          "https://chrome.google.com/webstore/detail/stocks-house/mpdkicmbmbljbgncgphonhgjebnapfop?hl=en",
        edge: "https://chrome.google.com/webstore/detail/stocks-house/mpdkicmbmbljbgncgphonhgjebnapfop?hl=en",
        opera:
          "https://chrome.google.com/webstore/detail/stocks-house/mpdkicmbmbljbgncgphonhgjebnapfop?hl=en",
        firefox: "https://addons.mozilla.org/en-US/firefox/addon/stocks-house/",
        safari: "https://apps.apple.com/tr/app/stocks-house/id1573098323?mt=12",
      },
    };
  },
  components: {
    MoreTools,
    Newsletter,
  },
  async beforeMount() {
    this.browser.name =
      this.browser.name === "edge-chromium" ? "edge" : this.browser.name;

    let isBrave =
      (navigator.brave && (await navigator.brave.isBrave())) || false;
    if (isBrave) this.browser.name = "brave";
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      this.isMobile = window.innerWidth < 700;
    },
  },
};
</script>

<style lang="scss">
@import "~@/inc/reset.scss";
@import "~@/inc/common.scss";
html {
  min-width: 100vw;
  min-height: 100vh;
  background: $bg;
}
.content {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  .logo {
    position: absolute;
    left: 0px;
    display: block;
    margin: 0 auto;
    width: 120px;
    margin-top: 0px;
  }
  h1 {
    font-weight: 300;
    letter-spacing: -0.04em;
    font-size: 60px;
    line-height: 130%;
    text-align: center;
    font-style: normal;
    color: #202021;
    width: 100%;
    max-width: 1200px;
    transform: scale(0.6);
    transform-origin: top center;
    position: relative;
    img {
      position: absolute;
      display: block;
      right: 360px;
      bottom: -30px;
      z-index: -10;
    }
    span {
      color: $blue;
    }
  }
  .frame {
    width: 1200px;
    height: 615px;
    background: url("~@/assets/frame.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 30px;
    transform: scale(0.7);
    transform-origin: top center;
    z-index: 8;
    position: relative;
    margin-top: -20px;
  }
  .mobile-frame,
  .mobile-cta {
    display: none;
  }
  .download {
    text-align: center;
    margin: 0 auto;
    margin-top: -310px;
    z-index: 10;
    position: relative;
    width: 600px;
    .download-buttons {
      padding-bottom: 13px;
      a {
        display: inline-block;
        user-select: none;
        background: $dark;
        border-radius: 32px;
        width: 56px;
        height: 56px;
        margin: 0px 8px;
        transition: all 0.2s;
        transform: scale(1);
        &:hover {
          transform: scale(1.04);
        }
        &:active {
          transform: scale(0.96);
        }
        img {
          width: 33px;
          height: 33px;
          margin-top: 11px;
        }
      }
    }
    .download-button {
      display: inline-block;
      background: $dark;
      padding: 16px 32px;
      color: #fff;
      font-weight: 600;
      border-radius: 32px;
      transition: all 0.2s;
      transform: scale(1);
      user-select: none;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(0.98);
      }
      img {
        display: inline-block;
        width: 33px;
        float: left;
      }
      span {
        display: inline-block;
        float: left;
        margin-top: 8px;
        margin-left: 16px;
      }
    }
    .path {
      position: absolute;
      left: 60px;
      top: 60px;
      user-select: none;
    }
  }
  .other-browsers,
  .choose-your-browser {
    margin-top: 24px;
    font-weight: 500;
    text-decoration: underline;
    user-select: none;
    color: #585d83;
    display: block;
    letter-spacing: -0.03em;
  }
  .choose-your-browser {
    text-decoration: none;
  }
}
@media (max-height: 670px) {
  .content {
    // transform: scale(0.9);
    transform-origin: top center;
  }
}
@media (min-width: 1023px) and (max-width: 1440px) {
  .content {
    width: 100%;
    padding: 0px 30px;
    box-sizing: border-box;
    transform-origin: top center;
    h1 {
      transform: scale(0.7);
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .logo {
      margin-left: 30px;
      margin-top: 30px;
    }
    .frame {
      transform: scale(0.8);
    }
    .download {
      margin-top: -260px;
    }
  }
}
@media (min-width: 1441px) {
  .content {
    width: 1380px;
    transform-origin: top center;
    padding-top: 20px;
    h1 {
      transform: scale(0.8);
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .frame {
      transform: scale(1);
      margin-top: 10px;
    }
    .download {
      margin-top: -180px;
    }
  }
}

//LOWER THAN 1023PX
@media (min-width: $mobile-max) and (max-width: 1023px) {
  .content {
    width: 100vw;
    transform-origin: top center;
    padding-top: 20px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px 40px;
    .logo {
      margin: 20px 40px;
    }
    h1 {
      transform: scale(0.5);
      width: 150%;
      margin-top: 13px;
      position: absolute;
      text-align: right;
      right: 50px;
      display: block;
      transform-origin: top right;
      img {
        right: -20px;
      }
    }
    .frame {
      margin-top: 150px;
      transform: scale(0.6);
    }
  }
}

//MOBILE
@media (max-width: $mobile-max) {
  .content {
    transform-origin: top center;
    width: 100%;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    .logo {
      position: inherit;
      width: 90px;
      margin-bottom: 24px;
    }
    h1 {
      width: 300px;
      font-size: 32px;
      transform: none;
      img {
        width: 170px;
        position: initial;
        right: initial;
        bottom: initial;
        display: inline;
        margin-left: 90px;
        top: -15px;
        position: relative;
      }
    }
    .frame {
      display: none;
    }
    .mobile-frame {
      display: block;
      width: 100%;
      height: auto;
      img {
        max-width: 300px;
        box-shadow: 0px 124px 160px rgba(233, 136, 134, 0.2);
        border-radius: 15px;
      }
    }
    .download {
      display: none;
    }
    .mobile-cta {
      display: block;
      position: relative;
      .button {
        display: inline-block;
        background: $dark;
        padding: 16px 32px;
        color: #fff;
        font-weight: 600;
        border-radius: 32px;
        user-select: none;
        margin-top: 32px;
      }
      .path {
        position: absolute;
        left: -30px;
        top: 70px;
        width: 60px;
      }
    }
  }
}

footer {
  width: 100%;
  text-align: center;
  color: $dark;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 500;
}

#app {
  font-family: "Work Sans";
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  width: 100%;
}
</style>
