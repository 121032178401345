<style lang="scss" scoped>
@import "~@/inc/common.scss";
.newsletter-container {
  text-align: left;
  max-width: 560px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  float: left;
  h2 {
    color: $blue;
    font-weight: 700;
    font-size: 24px;
    img {
      width: 100%;
    }
  }
  p {
    font-size: 15px;
    line-height: 23px;
    margin: 45px 0;
    font-weight: 400;
    color: $dark;
    strong {
      color: $blue;
    }
  }
  .input-container {
    max-width: 558px;
    width: 100%;
    height: 65px;
    float: left;
    position: relative;
    z-index: 10;
    input {
      max-width: 557px;
      width: 100%;
      height: 64px;
      border: 1px solid $border-light;
      border-radius: 5px;
      position: absolute;
      left: -1px;
      display: inline-block;
      font-family: "Work Sans";
      font-size: 18px;
      padding: 0px 24px;
      box-sizing: border-box;
      &:focus {
        border-color: $green;
      }
    }
    button {
      position: absolute;
      right: 12px;
      top: 10.5px;
      display: inline-block;
      background: url("~@/assets/subscribe-button.svg") no-repeat;
      width: 143px;
      height: 43px;
      transition: all 0.2s;
      cursor: pointer;
      &:hover {
        transform: scale(1.02);
      }
      &:active {
        transform: scale(0.98);
      }
    }
    span {
      position: absolute;
      display: inline-block;
      top: 80px;
      font-size: 14px;
      color: $blue;
      font-weight: 500;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<template>
  <div class="newsletter-container">
    <h2>
      <img src="~@/assets/h1-interested.svg" alt="" />
    </h2>
    <p>
      Hi there,
      <br /><br />
      You made it this far, and here are some flowers for you
      <span style="font-size: 24px">🤗💐</span> <br /><br />
      We are <a href="https://panda.network" target="_blank">?Panda Network</a>,
      we design and develop the tools that creatives need in their workflow. We
      have launched 26 different products by the end of 2016 which took us to
      the top of the makers list on Product Hunt. <br /><br />
      Join our newsletter and get notified when we release new tools.
      <br /><br />
      Promise, we will only email when there is new stuff 🌳
      <br /><br />
      <i
        >Estimated newsletter frequency:
        <strong>1 email every ~3months</strong></i
      >
    </p>
    <div class="input-container">
      <div :class="{ invalid: hasError }">
        <input
          ref="input"
          type="text"
          v-model="email"
          @keyup.enter="subscribe"
        />
        <button @click="subscribe" />
      </div>
      <transition name="fade">
        <span v-if="status">{{ status }}</span>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  name: "Newsletter",
  components: {},
  data() {
    return {
      email: "",
      status: "",
      isLoading: false,
      hasError: false,
      errorTimeout: null,
    };
  },
  methods: {
    subscribe() {
      const that = this;
      //asd
      let email_re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.email === "") {
        this.onStatus(true);
        this.$refs.input.focus();
        this.status = "Please enter your email address.";
      } else if (!email_re.test(this.email)) {
        this.onStatus(true);
        this.$refs.input.focus();
        this.status = "Please enter a valid email address.";
      } else {
        //Seems like a valid email
        this.hasError = false;
        this.status = "Just a second...";

        axios
          .post(
            "https://empweekly.com/sendy/subscribe",
            qs.stringify({
              email: that.email,
              // site: 'stockshouse'
              list: "Ykn86Mok1CKXB2xS763fz892Dg",
              api_key: "aT3dOMi2pRtyya5VJUkK",
              boolean: true,
            })
          )
          .then((res) => res.data)
          .then((res) => {
            if (res != "1") {
              that.onStatus(true, true);
              that.status = res;
              return;
            }

            that.status = "Thank you, you're successfully subscribed 🙏";
            that.onStatus(false);
          })
          .catch((error) => {
            that.status =
              "Something wrong happened, please contact info@stocks.house";
            that.onStatus(error);
          });
      }
    },
    onStatus(isError) {
      this.hasError = !!isError;
      clearTimeout(this.errorTimeout);

      this.errorTimeout = setTimeout(() => {
        this.hasError = false;
        this.status = "";
      }, 5000);
    },
  },
};
</script>
